import React, { useState, useEffect} from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Icofont from "react-icofont";
import ContactForms from "../components/mmc/ContactForm";

const ScrollToTop = ({ children }) => {
  const [scrolled, setScrolled] = useState(false);
  const {t} = useTranslation();
  const [isShowingModal, setModalVisibility] = useState(false);
  const showDialog = () => {
    setModalVisibility(true);
  };
  useEffect(() => {
    const handleScroll = () => {
      if (window.pageYOffset > 100) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => window.removeEventListener("scroll", handleScroll);
  }, [scrolled]);

  const backToTop = (e) => {
    e.preventDefault();
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
          <Modal
        show={isShowingModal}
        onHide={() => {
          setModalVisibility(false);
        }}
        style={{
          color: "#333",
          backgroundColor: "rgba(0, 0, 0, 0.3)",
        }}
        className="black-color"
        fullscreen
      >
        <Modal.Header
          style={{
            backgroundColor: "#F5F3F1",
            display: "flex",
            flexDirection: "row",
            justifyContent: "end",
            alignItems: "center",
            color: "#8B0000",
          }}
        >
          <span
            className=""
            onClick={(e) => {
              setModalVisibility(false);
            }}
          >
            <Icofont icon="close" size="2" />
          </span>
        </Modal.Header>
        <Modal.Body
          style={{
            backgroundColor: "#F5F3F1",
          }}
        >
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12">
              <ContactForms isFromNavbar />
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <a
        href="!#"
        id="back-to-top"
        className={scrolled ? "show" : ""}
        title="Back to top"
        onClick={backToTop}
      >
        &uarr;
      </a>
      
      {/* <span 
        href="!#"
        id="floating-contact"
        className={"show btn btn-circle btn-sm secondary-bg"}
      
        onClick={(e) => {
          showDialog();
        }}
        style={{
          margin: 0,
          display: "flex",
          justifyContent: "center",
        }}
      >
        <b>{t("contact-us")}</b>
        </span> */}

      <img
              src={require(`../assets/images/contact.png`)}
              className="show"
              id="floating-contact"
              onClick={(e) => {
                showDialog();
              }}
          />
      {children}
    </>
  );
};

export default ScrollToTop;
