import React, { useState } from "react";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

const EventPreview = ({ event, index, isNextPreview, ...props }) => {

  const { t } = useTranslation();
  const history = useHistory();
  const [isFullscreen, setIsFullscreen] = useState(false);

  const parsedDate = new Date(event.date);
  const dateParts = {
    day: format(parsedDate, "dd"),
    monthNumber: format(parsedDate, "MM"),
    monthName: format(parsedDate, "MMMM"),
    year: format(parsedDate, "yyyy"),
    time: format(parsedDate, "hh:mm aa"),
    date: format(parsedDate, "yyyy-MM-dd"),
  };

  return (
    <div
      className={`event-preview-big white-bg text-center`}
      style={{
        padding: "3px",
        margin: "2px",
        cursor: "pointer",
      }}
      title={event.name}
      onClick={() => {
        history.push(`${process.env.PUBLIC_URL}/events/${event.hashid}`);
      }}
    >
      <div className="post-img" style={{position: 'relative'}}>
        <div className= 'event-preview-title-big'>{event.name}</div>
        <img
          className="img-fluid event-image"
          src={event.image}
          alt=""
          style={{
            objectFit: "cover",
          }}
        />
      </div>
      {/*<div>*/}
      {/*  <h4 className="ml-10" style={{ textAlign: "left" }}>*/}
      {/*    {dateParts["day"]} {dateParts["monthName"]} {dateParts["year"]}*/}
      {/*  </h4>*/}
      {/*  <h3>*/}
      {/*    <b>{event.name}</b>*/}
      {/*  </h3>*/}
      {/*  <a*/}
      {/*    href="#!"*/}
      {/*    className="btn btn-color btn-sm btn-default white-color"*/}
      {/*    onClick={() => {*/}
      {/*      setIsFullscreen(!isFullscreen);*/}
      {/*    }}*/}
      {/*  >*/}
      {/*    <span>*/}
      {/*      {t("event-see-more")}*/}
      {/*      <i className="icofont icofont-arrow-right"></i>*/}
      {/*    </span>*/}
      {/*  </a>*/}
      {/*</div>*/}
    </div>
  );
};
export default EventPreview;
