import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Icofont from "react-icofont";
import ContentLoader from "react-content-loader";
import Slider from "react-slick";
import axios from "axios";

import HeadingSection from "./HeadingSection";

const ExternalResources = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [externalResourcesData, setExternalResourcesData] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    (async () => {
      setTimeout(async () => {
        setIsLoading(false);
        const response = await axios.get(
          "https://mmc-api.nassoftwarecorp.com/api/resources/all"
        );
        console.log("RESOURCES");
        console.log(response.data.data);
        setExternalResourcesData(response.data.data);
      }, 3000);
    })();
  }, []);

  const sliderSettings = {
    className: "member",
    slidesToShow: Math.min(externalResourcesData.length, 6),
    arrows: true,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: Math.min(externalResourcesData.length, 5),
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: Math.min(externalResourcesData.length, 3),
        },
      },
      {
        breakpoint: 501,
        settings: {
          slidesToShow: Math.min(externalResourcesData.length, 2),
        },
      },
    ],
  };

  return (
    <section className="white-bg">
      <div className="container">
        <div className="row">
          <HeadingSection title={t("external-links")} />
        </div>
        <div className="row">
          <div
            className="remove-padding"
            style={
              isLoading
                ? {
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                  }
                : {
                    width: "80vw",
                    marginLeft: "6%",
                  }
            }
          >
            {isLoading ? (
              <ContentLoader
                speed={1}
                height={30}
                backgroundColor="#ECECEC"
                foregroundColor="#F5F3F1"
                style={{
                  width: "100%",
                  height: "100%",
                  display: "inline-block",
                }}
                className="text-center ml-20"
              >
                <rect x="0" y="0" rx="5" ry="5" width="100%" height="30" />
              </ContentLoader>
            ) : (
              <Slider {...sliderSettings}>
                {externalResourcesData.map((resource, index) => (
                  <div
                    className="client-logo text-center"
                    key={`ext-resource-${index}`}
                  >
                    <a
                      href={resource.url || "#!"}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {/* <Icofont
                        icon={resource.icon || "arrow-right"}
                        className="font-40px dark-icon white-bg-icon circle-icon fade-icon"
                      /> */}
                      <img
                        src={
                          resource.icon_photo ? resource.icon_photo.url :  "/default_resource_icon.svg"
                        }
                        className="img-fluid"
                        alt={resource.name}
                        style={{
                          width: "100px",
                          boxShadow: "5px 6px 23px -12px rgba(0,0,0,0.83)",
                        }}
                      />
                    </a>
                    {resource.name && <h5>{resource.name}</h5>}
                  </div>
                ))}
              </Slider>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default ExternalResources;
