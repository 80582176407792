import React, { useState, useRef } from "react";
import Icofont from "react-icofont";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { useTranslation } from "react-i18next";

import logoBig from "../../assets/images/mmc-logo-big.png";

const AboutSection = ({ title, tagline, children, ref, ...otherProps }) => {
  const [viewed, setViewed] = useState(false);
  const { t } = useTranslation();

  const dataTabs = [
    {
      id: "charm",
      title: t("about-us-charm"),
      text: [
        "Proviene de las bodas de cana.",
        "Transformar matrimonios y llevarlos a la vida sacramental y que puedan vivir las gracias del sacramento del matrimonio con alegría.",
      ],
    },
    {
      id: "mission",
      title: t("about-us-mission"),
      text: "Somos un movimiento abierto a todas las parejas católicas, estén o no casados por la iglesia, que quieran crecer como parejas en lo espiritual y en lo humano. Creando entre la pareja un estilo de vida y relación basado en el amor de Dios, descubriendo lo que puede hacer Jesús en sus vidas y guiarlas hacia un matrimonio sacramental. Fomentando la comunicación, el amor mutuo, el respeto y el perdón entre los esposos.",
    },
    {
      id: "purpose",
      title: t("about-us-purpose"),
      text: [
        "Crear entre las parejas un estilo de vida y relación basado en el amor de Dios",
        "Fomentar la comunicación, el amor, el respeto mutuo y el perdón entre los esposos.",
        "Crear conciencia en la pareja de la necesidad e importancia del sacramento del matrimonio",
        "Ensenar a las parejas a resolver sus conflictos",
        "Formar parejas fuertes y sólidas espiritualmente",
        "Solicitar y promover actividades de recaudar fondos para llevar a cabo nuestras actividades: Congresos de parejas, congresos Pro-vida, retiros, talleres de formación y otros.",
      ],
    },
  ];

  return (
    <section id="about" className="pb-80 white-bg mt-20" ref={ref}>
      <div className="container">
        <div className="row">
          <div className="col-md-5 mb-20">
            <img className="img-fluid" src={logoBig} alt="" />
          </div>
          <div className="col-md-7">
            <h2 className="text-uppercase font-700 mt-0 text-left">
              <span className="font-100">Somos el</span> Movimiento Matrimonial
              Católico
            </h2>
            <p className="mt-20" style={{ textAlign: "justify" }}>
              Somos un movimiento Laico con sede en Estados Unidos De Norte
              América en el estado de New York, abierto para todas las parejas
              católicas estén o no casados por la iglesia que quieran crecer
              como parejas junto a Jesús y estén dispuestos a compartir su
              experiencia con otras parejas.
            </p>
            <p style={{ textAlign: "justify" }}>
              La principal actividad que realizamos es nuestra reunión de grupo
              que hacemos periodicamente y cada grupo está formado por siete
              parejas y una pareja de servidores, estas ocho parejas forman un
              grupo y siempre son los mismos que se reúnen (CON EL TIEMPO LLEGAN
              A SER UNA FAMILIA)
            </p>
            <p style={{ textAlign: "justify" }}>
              Ofrecemos retiros de crecimiento de fin de semana y de un día,
              vigías, talleres, congreso de parejas, misas de familia, tardes de
              oración, así como actividades recreativas para el crecimiento
              espiritual y social para toda la familia; esto todo bajo lo que
              nos enseña nuestra iglesia católica.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12 col-md-10 col-lg-8 offset-md-1 offset-lg-2">
            <h3 className="mt-50 mb-100 text-uppercase text-center font-700">
              Creemos en el matrimonio
            </h3>
            <Tabs className="light-tabs mt-50">
              <TabList
                className="nav nav-tabs text-center"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  direction: "row",
                }}
              >
                {dataTabs
                  .filter((v, i) => i < 3)
                  .map((tab) => (
                    <Tab key={tab.id} className="nav-item react-tabs__tab">
                      <span>{tab.title}</span>
                    </Tab>
                  ))}
              </TabList>
              <div className="tab-content" style={{ textAlign: "justify" }}>
                {dataTabs
                  .filter((v, i) => i < 3)
                  .map((tab) => (
                    <TabPanel key={tab.id} className="tab-pane show in active">
                      {tab.id !== "purpose" ? (
                        Array.isArray(tab.text) ? (
                          tab.text.map((item, index) => (
                            <p
                              data-aos-delay={100}
                              data-aos={"fade-in"}
                              key={`${tab.title}-text-${index}`}
                              className="mr-20 ml-20"
                            >
                              {item}
                            </p>
                          ))
                        ) : (
                          <p
                            data-aos-delay={100}
                            data-aos={"fade-in"}
                            className="mr-20 ml-20"
                          >
                            {tab.text}
                          </p>
                        )
                      ) : (
                        <ul>
                          {tab.text.map((item, index) => (
                            <li
                              data-aos-delay={100}
                              data-aos={"fade-in"}
                              key={`${tab.title}-text-${index}`}
                              className="mr-20 ml-20"
                            >
                              {item}
                            </li>
                          ))}
                        </ul>
                      )}
                    </TabPanel>
                  ))}
              </div>
            </Tabs>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutSection;
