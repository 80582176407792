import React, { useEffect, useState, useMemo } from "react";
import { useLocation } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import { useLax } from "../../helpers/use-lax";
import Loader from "../../components/Loader/Loader";
import HeaderSection from "../../components/mmc/Header";
import { useTranslation } from "react-i18next";
import axios from "axios";
import BlogPost from "../../components/mmc/BlogPost";
import { useHistory } from "react-router-dom";
import Pagination from "../../components/Pagination/Pagination";
import Icofont from "react-icofont";
import ContentLoader from "react-content-loader";

const useQuery = () => {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
};

const BlogPage = () => {
  const { t } = useTranslation();
  const query = useQuery();
  const history = useHistory();
  useLax();

  const [searchValue, setSearchValue] = useState(query.get("search") || "");
  const [isLoading, setIsLoading] = useState(true);
  const [articleList, setArticleList] = useState([]);
  const [paginationData, setPaginationData] = useState(null);
  const [currentPage, setCurrentPage] = useState(query.get("page") || 1);

  const fetchArticles = async (searchValue, pageNumber) => {
    try {
      setIsLoading(true);

      const response = await axios.get("/articles/all", {
        params: {
          ...(searchValue ? { search: searchValue } : {}),
          ...(pageNumber ? { page: pageNumber } : {}),
        },
      });

      setArticleList(response.data.data);
      setPaginationData(response.data.meta);

      console.log(response.data.meta);
    } catch (err) {
      setIsLoading(false);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    console.log(paginationData);
    const search = query.get("search") || "";
    const page = query.get("page") || 1;

    fetchArticles(search, page);
  }, [query.get("search"), query.get("page")]);

  useEffect(() => {
    AOS.init();
    AOS.refresh();

    const timer = setTimeout(async () => {
      await fetchArticles();
    }, 1000);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  const onSearch = async (e) => {
    if (e) {
      e.preventDefault();
    }

    history.push(`${process.env.PUBLIC_URL}/blog?search=${searchValue}`);
  };

  return (
    <Loader>
      <HeaderSection type={"white"} />
      <div className="container mt-50" style={{ backgroundColor: "#f1f1f1" }}>
        <div className="row">
          <hr className="mt-100" />
          <form
            onSubmit={onSearch}
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <div className="col-lg-4 col-md-4 col-sm-10 pr-10 offset-lg-4 offset-md-4">
              <div className="form-group">
                <input
                  type="text"
                  name="search"
                  style={{ marginTop: "20px" }}
                  className="form-control"
                  placeholder={t("search")}
                  id="search"
                  value={searchValue}
                  onChange={(e) => {
                    const { value } = e.target;
                    setSearchValue(value);
                  }}
                  disabled={isLoading}
                />

                <div className="help-block with-errors mt-20"></div>
              </div>
            </div>
            <div className="col-lg-2 col-md-2 col-sm-2 mt-1" onClick={onSearch}>
              <button
                style={{ marginTop: "10px" }}
                className="btn btn-color"
                disabled={isLoading}
              >
                {/* <Icofont icon="search-2" size="2" /> */}
                <i className="icofont icofont-search-2"></i>
              </button>
            </div>
          </form>
        </div>
        <div className="row">
          <hr className="mt-20" />
          {isLoading
            ? [...Array(3).keys()].map((item, index) => (
                <div
                  className="post col-lg-4 col-md-4 col-sm-4"
                  key={`skeleton-${index}`}
                >
                  <ContentLoader
                    speed={1}
                    height={350}
                    backgroundColor="#ECECEC"
                    foregroundColor="#F5F3F1"
                    style={{
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <rect x="0" y="0" rx="5" ry="5" width="100%" height="280" />
                    <rect
                      x="0"
                      y="300"
                      rx="5"
                      ry="5"
                      width="100%"
                      height="10"
                    />
                    <rect
                      x="0"
                      y="320"
                      rx="5"
                      ry="5"
                      width="100%"
                      height="10"
                    />
                    <rect
                      x="0"
                      y="340"
                      rx="5"
                      ry="5"
                      width="100%"
                      height="10"
                    />
                  </ContentLoader>
                </div>
              ))
            : articleList.map((article, index) => (
                <div
                  className="col-lg-4 col-sm-3 col-sm-6 mt-10"
                  key={`blog-${index}`}
                >
                  <BlogPost post={article} />
                </div>
              ))}
        </div>
        {articleList.length === 0 && searchValue && (
          <div className="row text-center">
            <h3>{`${t("not-found-for")}: ${searchValue}`}</h3>
            <Icofont icon="close-line-circled" size="5" />
          </div>
        )}
        <div className="row">
          <hr className="mt-10" />
          <div className="col-lg-12 col-md-12 col-sm-12">
            {!isLoading && paginationData && articleList.length > 0 && (
              <Pagination
                postsPerPage={paginationData.per_page}
                totalPosts={paginationData.total}
                page={(e, currentPage) => {
                  e.preventDefault();

                  history.push(
                    `${process.env.PUBLIC_URL}/blog?search=${searchValue}&page=${currentPage}`
                  );
                }}
                currentPage={paginationData.current_page}
              />
            )}
            {!isLoading && paginationData && articleList.length > 0 && (
              <h5 className="text-center">
                {paginationData.from} - {paginationData.to} /{" "}
                {paginationData.total}
              </h5>
            )}
          </div>
          <hr className="mt-20" />
        </div>
      </div>
    </Loader>
  );
};

export default BlogPage;
