import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Slider from "react-slick";
import ContentLoader from "react-content-loader";
import { useTranslation } from "react-i18next";
import { Link as RouterLink, useHistory } from "react-router-dom";

import BlogPost from "./BlogPost";
import HeadingSection from "./HeadingSection";
import axios from "axios";

BlogShowcase.propTypes = {
  bgColor: PropTypes.string,
  isInArticlePage: PropTypes.bool,
  hideArticlesList: PropTypes.array,
};

BlogShowcase.defaultValues = {
  bgColor: "secondary",
  isInArticlePage: false,
  hideArticlesList: [],
};

function BlogShowcase({
  bgColor,
  showArrows,
  isInArticlePage,
  hideArticlesList,
  ...props
}) {
  const { t } = useTranslation();
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(true);
  const [blogData, setBlogData] = useState([]);

  useEffect(() => {
    setTimeout(async () => {
      const response = await axios.get("/articles/latest");
      setBlogData(
        hideArticlesList
          ? response.data.data.filter(
              (article) => !hideArticlesList.includes(article.hashid)
            )
          : response.data.data
      );
      setIsLoading(false);

      console.log("BLOG");
      console.log(response.data.data);
    }, 3000);
  }, []);

  const sliderSettings = {
    autoplay: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    className: "blog-slider",
    // variableWidth: true,
    // centerMode: true,
    // arrows: false,
    responsive: [
      {
        breakpoint: 901,
        settings: {
          slidesToShow: 2,
          // variableWidth: true,
          // centerMode: true,
          // arrows: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          // variableWidth: true,
          // centerMode: true,
          // arrows: false,
        },
      },
    ],
  };

  return (
    <section
      style={
        bgColor
          ? {
              backgroundColor: bgColor,
            }
          : {}
      }
    >
      <div className="container">
        {!isInArticlePage && (
          <div className="row">
            <HeadingSection title={t("blog-showcase")}>
              <a
                href="https://google.com"
                className="btn btn-color btn-xs btn-default"
              >
                <span>
                  {t("blog-see-more")}
                  <i className="icofont icofont-arrow-right"></i>
                </span>
              </a>
            </HeadingSection>
          </div>
        )}
        <div className="row mt-50">
          <Slider {...sliderSettings}>
            {isLoading
              ? [...Array(3).keys()].map((item, index) => (
                  <div className="post" key={`skeleton-${index}`}>
                    <ContentLoader
                      speed={1}
                      height={570}
                      backgroundColor="#ECECEC"
                      foregroundColor="#F5F3F1"
                      style={{
                        width: "100%",
                        height: "100%",
                      }}
                    >
                      <rect
                        x="0"
                        y="0"
                        rx="5"
                        ry="5"
                        width="100%"
                        height="460"
                      />
                      <rect
                        x="0"
                        y="470"
                        rx="5"
                        ry="5"
                        width="100%"
                        height="10"
                      />
                      <rect
                        x="0"
                        y="490"
                        rx="5"
                        ry="5"
                        width="100%"
                        height="10"
                      />
                      <rect
                        x="0"
                        y="510"
                        rx="5"
                        ry="5"
                        width="100%"
                        height="10"
                      />
                    </ContentLoader>
                  </div>
                ))
              : blogData.map((post, index) => (
                  <BlogPost key={post.hashid} post={post} />
                ))}
          </Slider>
        </div>
      </div>
    </section>
  );
}

export default BlogShowcase;
