import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Icofont from "react-icofont";
import ContentLoader from "react-content-loader";
import axios from "axios";

import HeadingSection from "./HeadingSection";

const SocialLinks = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [socialLinksData, setSocialLinksData] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    (async () => {
      setTimeout(async () => {
        setSocialLinksData([
          {
            url: "https://www.facebook.com/MMCatolico/",
            type: "facebook",
            style: { backgroundColor: "#1B74E4", color: "white" },
            icon: "icofont-facebook",
          },
          {
            url: "https://www.youtube.com/channel/UCuz6zURV0tx0il1OdEgW21A/",
            style: { backgroundColor: "red", color: "white" },
            type: "youtube",
            icon: "icofont-brand-youtube",
          },
          {
            url: "https://www.instagram.com/mmcatolico/?hl=en",
            style: {
              background:
                "radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%)",
              color: "white",
            },
            type: "instagram",
            icon: "icofont-instagram",
          },
        ]);
        setIsLoading(false);
      }, 3000);
    })();
  }, []);

  return (
    <section>
      <div className="container">
        <div className="row">
          <HeadingSection title={t("social-links")} />
        </div>
        <div className="row">
          <div
            className="col-md-12 col-lg-12 col-sm-12 remove-padding"
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            {isLoading
              ? [...Array(3).keys()].map((item, index) => (
                  <ContentLoader
                    speed={1}
                    height={100}
                    backgroundColor="#ECECEC"
                    foregroundColor="#F5F3F1"
                    style={{
                      width: "100%",
                      height: "100%",
                      display: "inline-block",
                    }}
                    className="text-center ml-20"
                    key={`social-link-skeleton-${index}`}
                  >
                    <circle cx="50" cy="50" r="50" />
                  </ContentLoader>
                ))
              : socialLinksData.map((socialLink, index) => (
                  <div
                    className="feature-box text-center col-sm-2 offset-sm-2 col-md-2 col-lg-2 mt-20 ml-20"
                    data-aos-delay={`${index}00`}
                    data-aos={"fade-up"}
                    data-aos-duration={1000}
                    key={`social-link-${index}`}
                  >
                    <a
                      href={socialLink.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Icofont
                        style={socialLink.style}
                        icon={socialLink.icon}
                        className="font-40px dark-icon white-bg-icon circle-icon fade-icon"
                      />
                    </a>
                  </div>
                ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default SocialLinks;
