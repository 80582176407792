import React, { useRef, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import ContentLoader from "react-content-loader";
import axios from "axios";

import EventPreview from "./EventPreview";

const EventsShowcase = () => {
  const { t } = useTranslation();
  const ref = useRef(null);
  const [isLoading, setIsLoading] = useState(true);
  const [eventsData, setEventsData] = useState([]);

  useEffect(() => {
    (async () => {
      const response = await axios.get("/events/all?sort=created_at:desc");
      setEventsData(response.data.data);
      setIsLoading(false);

      console.log("EVENTS");
      console.log(response.data.data);
    })();
  }, []);

  return (
    <section ref={ref} className="" style={{ backgroundColor: "#F1F1F1" }}>
      <div className="row">
        <div className="col-sm-12 page-heading">
          <h2 className="font-400 title-font ">{t("events")}</h2>
        </div>
      </div>
      <div
        className="mt-10 dark-color events-container"
        style={{
          display: "flex",
          flexDirection: "flow",
          flexWrap: "wrap",
          justifyContent: "center",
        }}
      >
        {isLoading ? (
          <ContentLoader
            speed={1}
            height={300}
            backgroundColor="#c0b8a1"
            foregroundColor="#F5F3F1"
            style={{
              width: "100%",
            }}
          >
            <rect x="0" y="0" rx="5" ry="5" width="100%" height="30" />
            <rect x="0" y="50" rx="5" ry="5" width="100%" height="30" />
            <rect x="0" y="100" rx="5" ry="5" width="50%" height="30" />
            <rect x="0" y="150" rx="5" ry="5" width="80%" height="30" />
          </ContentLoader>
        ) : (
          eventsData.map((event, i) => {
            return <EventPreview key={`event-${i}`} index={i} event={event} />;
          })
        )}
      </div>
    </section>
  );
};

export default EventsShowcase;
