import React, { useEffect, useRef, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { useLax } from "../../helpers/use-lax";
import Loader from "../../components/Loader/Loader";
import HeaderSection from "../../components/mmc/Header";
import { useTranslation } from "react-i18next";
import ContentLoader from "react-content-loader";
import axios from "axios";
import { format } from "date-fns";
import { useHistory } from "react-router-dom";
import { Link as RouterLink, useParams } from "react-router-dom";
import Lightbox, { ImagesListType } from "react-spring-lightbox";
import Icofont from "react-icofont";

const EventDetailsPage = () => {
  const { eventId } = useParams();
  const { t } = useTranslation();
  const history = useHistory();
  useLax();
  const ref = useRef(null);

  const [isLoading, setIsLoading] = useState(true);
  const [eventData, setEventData] = useState(null);
  const [isShowingLightbox, setIsShowingLightbox] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  useEffect(() => {
    (async () => {
      const response = await axios.get(`/events/show/${eventId}`);
      setEventData(response.data.data);
      setIsLoading(false);

      console.log("EVENT:", eventId);
      console.log(response.data.data);
    })();
  }, []);

  const parsedDate = eventData && new Date(eventData.date);
  const dateParts = eventData && {
    day: format(parsedDate, "dd"),
    monthNumber: format(parsedDate, "MM"),
    monthName: format(parsedDate, "MMMM"),
    year: format(parsedDate, "yyyy"),
    time: format(parsedDate, "hh:mm aa"),
    date: format(parsedDate, "yyyy-MM-dd"),
  };

  const goPrevios = () => {
    setCurrentImageIndex(Math.max(0, currentImageIndex - 1));
  };

  const goNext = () => {
    eventData &&
      setCurrentImageIndex(
        Math.min(eventData.photos.length - 1, currentImageIndex + 1)
      );
  };
  const images = eventData
    ? eventData.photos.filter((item) => item.type !== "video")
    : [];
  const videos = eventData
    ? eventData.photos.filter((item) => item.type === "video")
    : [];
  const eventImages = images.map((item, index) => ({
    src: item.url,
    loading: "lazy",
    alt: `image #${index + 1}`,
  }));

  console.log(eventImages);

  return (
    <Loader>
      <HeaderSection type={"white"} />
      <hr className="mt-10" />
      <div className="container">
        <section ref={ref} className="" style={{ backgroundColor: "#F1F1F1" }}>
          {isLoading ? (
            <ContentLoader
              speed={1}
              height={300}
              backgroundColor="#c0b8a1"
              foregroundColor="#F5F3F1"
              style={{
                width: "100%",
              }}
              className="mt-20"
            >
              <rect x="0" y="0" rx="5" ry="5" width="100%" height="30" />
              <rect x="0" y="50" rx="5" ry="5" width="100%" height="30" />
              <rect x="0" y="100" rx="5" ry="5" width="50%" height="30" />
              <rect x="0" y="150" rx="5" ry="5" width="80%" height="30" />
            </ContentLoader>
          ) : (
            <React.Fragment>
              <div className="row mt-20">
                <div
                  className="col-lg-12 col-md-12 col-sm-12 offset-lg-1 offset-md-1 offset-sm-1 font-700 mb-10"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginTop: "50px",
                  }}
                >
                  <RouterLink
                    to={`${process.env.PUBLIC_URL}/events`}
                    title="Events"
                  >
                    {t("events")}
                  </RouterLink>
                  <span>\</span>
                  <span>{eventData && eventData.name}</span>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-8 section-heading dark-color">
                  <h2 className="font-400">{eventData.name}</h2>
                  <h4>
                    {eventData.date_range}
                  </h4>
                </div>
              </div>
              {videos.length ? (
                  <div
                      className="mt-10 dark-color"
                      style={{ justifyContent: "center" }}
                  >
                    <h3 className="text-center">
                      <b>Videos</b>
                    </h3>
                    {videos.map((video) => (
                        <video
                            className={"event-video"}
                            style={{
                              margin: "0 auto",
                              marginBottom: "10px",
                              display: "block",
                              maxWidth: "800px",
                            }}
                            src={video.url}
                            controls
                        ></video>
                    ))}
                  </div>
              ) : null}
              {images.length ? (
                <div
                  className="mt-10 dark-color"
                  style={{ justifyContent: "center" }}
                >
                  <h3 className="text-center">
                    <b>{t("images")}</b>
                  </h3>
                  <Lightbox
                    galleryTitle={eventData && eventData.name}
                    isOpen={isShowingLightbox}
                    currentIndex={currentImageIndex}
                    images={eventImages}
                    onPrev={goPrevios}
                    onNext={goNext}
                    onClose={() => {
                      setIsShowingLightbox(false);
                    }}
                    style={{ background: "black" }}
                    pageTransitionConfig={{
                      from: { transform: "scale(0.75)", opacity: 0 },
                      enter: { transform: "scale(1)", opacity: 1 },
                      leave: { transform: "scale(0.75)", opacity: 0 },
                      config: { mass: 1, tension: 320, friction: 32 },
                    }}
                    renderHeader={() => (
                      <div
                        className="pt-20 pl-20"
                        onClick={() => {
                          setIsShowingLightbox(false);
                        }}
                      >
                        <Icofont
                          style={{ zIndex: 10 }}
                          icon="ui-close"
                          className="font-20px white-icon"
                          onClick={goPrevios}
                        />
                      </div>
                    )}
                    renderPrevButton={({ canPrev }) =>
                      canPrev && (
                        <Icofont
                          style={{ zIndex: 10 }}
                          icon="simple-left"
                          className="font-60px white-icon"
                          onClick={goPrevios}
                        />
                      )
                    }
                    renderNextButton={({ canNext }) =>
                      canNext && (
                        <Icofont
                          style={{ zIndex: 10 }}
                          icon="simple-right"
                          className="font-60px white-icon"
                          onClick={goNext}
                        />
                      )
                    }
                  />
                  <div
                    id="event-gallery"
                    className="col-lg-12 col-md-12 col-sm-12"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      flexFlow: "row wrap",
                    }}
                  >
                    {eventImages.map((photo, i) => {
                      return (
                        <img
                          key={`event-img-${i}`}
                          className="event-gallery-thumbnail mr-10 mb-10"
                          src={photo.src}
                          alt=""
                          style={{
                            height: "auto",
                            width: "auto",
                            cursor: "zoom-in",
                          }}
                          onClick={() => {
                            setCurrentImageIndex(i);
                            setIsShowingLightbox(true);
                          }}
                        />
                      );
                    })}
                  </div>
                </div>
              ) : null}


            </React.Fragment>
          )}
        </section>
        <hr className="mt-100" />
      </div>
    </Loader>
  );
};

export default EventDetailsPage;
