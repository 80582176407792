import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { Link as RouterLink, useParams } from "react-router-dom";
import { useLax } from "../../helpers/use-lax";
import Loader from "../../components/Loader/Loader";
import HeaderSection from "../../components/mmc/Header";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import axios from "axios";
import { Autoplay } from "swiper";
import BlogShowcase from "../../components/mmc/BlogShowcase";

const ArticlePage = () => {
  const { articleId } = useParams();
  const { t, i18n } = useTranslation();
  useLax();

  const [isLoading, setIsLoading] = useState(true);
  const [articleData, setArticleData] = useState();

  const fetchArticle = async (articleId) => {
    try {
      setIsLoading(true);

      const response = await axios.get(`/articles/show/${articleId}`);

      setArticleData(response.data.data);
      setIsLoading(false);

      console.log(response.data.data);
    } catch (err) {}
  };

  useEffect(() => {
    // const timer = setTimeout(() => {
    fetchArticle(articleId);
  }, []);

  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  return (
    <Loader>
      <HeaderSection type={"white"} />
      <div
        className="container white-bg mt-50"
        style={{
          borderRadius: "8px",
          border: "1px solid #ddd",
        }}
      >
        <div className="row">
          {isLoading ? (
            <h2 className="main-color">Loading...</h2>
          ) : (
            <>
              <hr className="mt-100" />
              <div className="row">
                <div
                  className="col-lg-12 col-md-12 col-sm-12 offset-lg-1 offset-md-1 offset-sm-1 font-700"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    columnGap: "10px",
                  }}
                >
                  <RouterLink
                    to={`${process.env.PUBLIC_URL}/blog`}
                    title="Blog"
                  >
                    {t("blog")}
                  </RouterLink>
                  <span>\</span>
                  <span>{articleData.title}</span>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-8 offset-lg-2 col-md-8 offset-md-2 col-sm-12">
                  <h1 className="font-700">{articleData.title}</h1>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-8 offset-lg-2 col-md-8 offset-md-2 col-sm-12">
                  <div className="row">
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          width: "40px",
                          height: "40px",
                        }}
                      >
                        <img
                          src={articleData.author_picture}
                          alt=""
                          className="img-circle"
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                            borderRadius: "50%",
                          }}
                        />
                      </div>
                      <h4 className="ml-10">{articleData.author}</h4>
                      <h4
                        className="ml-10 pl-10"
                        style={{
                          color: "grey",
                          borderLeft: "1px solid",
                        }}
                      >
                        {format(
                          new Date(articleData.created_at),
                          "LLLL d, yyyy"
                        )}
                      </h4>
                    </div>
                  </div>
                  <div className="row mt-20">
                    <div className="col-lg-12">
                      <img
                        className="img-fluid"
                        src={articleData.thumbnail}
                        alt=""
                        style={{
                          maxHeight: "400px",
                          objectFit: "cover",
                        }}
                      />
                    </div>
                  </div>
                  <div className="row mt-40">
                    <div
                      className="col-lg-12"
                      dangerouslySetInnerHTML={{ __html: articleData.body }}
                      style={{
                        fontSize: "1.1rem",
                      }}
                    />
                  </div>
                  {articleData.source &&  <div className="row mt-40">
                    <span>Fuente: {articleData.source}</span>
                  </div>}
                  
                </div>
              </div>
              <div
                className="row pl-100 pr-100"
                style={{ display: "flex", justifyContent: "center" }}
              >
                <hr className="mt-50" />
                <BlogShowcase
                  bgColor="white"
                  hideArticlesList={[articleData.hashid]}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </Loader>
  );
};

export default ArticlePage;
