import React, { useEffect, useState, createRef, useRef } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { useLax } from "../../helpers/use-lax";
import Loader from "../../components/Loader/Loader";
import HeaderSection from "../../components/mmc/Header";
import { useTranslation } from "react-i18next";
import ContentLoader from "react-content-loader";
import RetreatPreview from "../../components/mmc/RetreatPreview";
import axios from "axios";

const RetreatsPage = () => {
  const { t, i18n } = useTranslation();
  useLax();

  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  const ref = useRef(null);
  const [isLoading, setIsLoading] = useState(true);
  const [retreats, setRetreats] = useState([]);

  useEffect(() => {
    (async () => {
      const response = await axios("/retreats/all?sort=created_at:desc");
      setRetreats(response.data.data);
      setIsLoading(false);

      console.log("retreats");
      console.log(response.data.data);
    })();
  }, []);

  return (
    <Loader>
      <HeaderSection type={"white"} />
      <div className="container">
        <hr className="mt-20" />
        <section ref={ref} className="" style={{ backgroundColor: "#F1F1F1" }}>
      <div className="row">
        <div className="col-sm-12 page-heading">
          <h2 className="font-400 title-font ">{t("retreats")}</h2>
        </div>
      </div>
      <div
        className="mt-10 dark-color events-container"
        style={{
          display: "flex",
          flexDirection: "flow",
          flexWrap: "wrap",
          justifyContent: "center",
        }}
      >
        {isLoading ? (
          <ContentLoader
            speed={1}
            height={300}
            backgroundColor="#c0b8a1"
            foregroundColor="#F5F3F1"
            style={{
              width: "100%",
            }}
          >
            <rect x="0" y="0" rx="5" ry="5" width="100%" height="30" />
            <rect x="0" y="50" rx="5" ry="5" width="100%" height="30" />
            <rect x="0" y="100" rx="5" ry="5" width="50%" height="30" />
            <rect x="0" y="150" rx="5" ry="5" width="80%" height="30" />
          </ContentLoader>
        ) : (
          retreats.map((retreat, i) => {
            return <RetreatPreview key={`event-${i}`} index={i} retreat={retreat} />;
          })
        )}
      </div>
    </section>
        <hr className="mt-100" />
      </div>
    </Loader>
  );
};

export default RetreatsPage;
