import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { useLax } from "../../helpers/use-lax";
import Loader from "../../components/Loader/Loader";
import HeaderSection from "../../components/mmc/Header";
import HeroCarousel from "../../components/mmc/HeroCarousel";
import { useTranslation } from "react-i18next";

import TeamShowcase from "../../components/mmc/TeamShowcase";
import YoutubeConsumer from "../../components/mmc/YoutubeConsumer";
import BlogShowcase from "../../components/mmc/BlogShowcase";
import SocialLinks from "../../components/mmc/SocialLinks";
import ExternalResources from "../../components/mmc/ExternalResources";
import ContactForm from "../../components/mmc/ContactForm";
import Footer from "../../components/mmc/Footer";

const HomePage = () => {
  const { t } = useTranslation();

  useLax();

  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  return (
    <Loader>
      <HeaderSection />
      <hr className="mt-60" />
      <HeroCarousel />
      <YoutubeConsumer />
      <BlogShowcase bgColor={"#EAEAEA"} />
      <TeamShowcase />
      <ExternalResources />
      <SocialLinks />
      <ContactForm />
      <Footer />
    </Loader>
  );
};

export default HomePage;
