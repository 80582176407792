import React from "react";
import logoWhite from "../assets/images/mmc-logo-big.png";
import logoBlack from "../assets/images/mmc-logo-big.png";
import logoText from "../assets/images/mmc-text.png";
const MainLogo = ({ collapse, showMenu }) => {
  return (
    <div className="navbar-headesr">
      <div className="logo">
        <a href={window.location.origin.toString()}>
          <img className="logo logo-display" src={logoWhite} alt="" />
          <img className="logo logo-scrolled" src={logoBlack} alt="" />

        </a>
      </div>
    </div>
  );
};

export default MainLogo;
