import React, { useState } from "react";
import Icofont from "react-icofont";
import Modal from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";

import protectorImage from "../../assets/images/mmc-protectors.png";

const ProtectorsSection = ({ ref, ...otherProps }) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const event = {};

  return (
    <>
      <Modal
        show={isOpen}
        onHide={() => {
          setIsOpen(!setIsOpen);
        }}
        style={{
          color: "#333",
          backgroundColor: "rgba(0, 0, 0, 0.3)",
        }}
        className="black-color"
        centered
      >
        <Modal.Header
          style={{
            backgroundColor: "#F5F3F1",
          }}
        >
          <Modal.Title
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div>{event.name}</div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            backgroundColor: "#F5F3F1",
          }}
        >
          <img
            src={event.image}
            alt=""
            style={{
              borderRadius: 10,
              border: "1px solid #777",
              boxShadow: "1px 2px 5px #BBB",
            }}
          />
          <p
            style={{
              color: "#444",
              textAlign: "center",
              marginTop: "15px",
            }}
          >
            {event.description}
          </p>
        </Modal.Body>
      </Modal>
      <div
        className="white-bg clearfix pt-70 pb-70 relative row"
        id="protectors"
      >
        <div className="col-md-6">
          <h2 className="text-uppercase font-700 pl-20 pr-20">
            Nuestros Protectores
          </h2>
          <div className="mt-30 pl-20 pr-20">
            <p style={{ textAlign: "justify" }}>
              En un gesto sin precedentes en la historia de la iglesia, el 21 de
              Octubre de 2001, Juan Pablo II beatificaba conjuntamente a un
              matrimonio: Los esposos italianos Luis Beltrame Quattrocchi y
              María Corsini. Y la diócesis de Roma celebraba por vez primera la
              memoria de los nuevos beatos el 25 de noviembre de 2001:
              Contrariamente a la tradición eclesial, la fecha de la celebración
              no era el dies natalis (fecha de la muerte: Nacimiento a la vida
              eterna), sino el aniversario de la boda de este matrimonio
              ejemplar, que fue el 25 de Noviembre de 1905.
            </p>
          </div>
        </div>
        <div className="white-bg col-md-6 col-sm-12">
          <img
            src={protectorImage}
            style={{ width: "100%", height: "auto", objectFit: "cover" }}
            alt=""
          />
        </div>
      </div>
    </>
  );
};

export default ProtectorsSection;
