import React, { useEffect, useRef, useCallback, useState } from "react";
import Slider from "react-slick";
import { useTranslation } from "react-i18next";
import HeadingSection from "./HeadingSection";

const baseUrl =
  "https://api.rss2json.com/v1/api.json?rss_url=https%3A%2F%2Fwww.youtube.com%2Ffeeds%2Fvideos.xml%3Fchannel_id%3D";
const channelId = "UCuz6zURV0tx0il1OdEgW21A";

const YoutubeVideo = ({ videoId, title }) => {
  const iframeRef = useRef(null);
  const defaultHeight = 375;
  const [videoHeight, setVideoHeight] = useState(
    iframeRef.current ? iframeRef.current.offsetWidth * 0.5625 : defaultHeight
  );

  const handleChangeVideoWidth = useCallback(() => {
    const ratio = window.innerWidth > 992 ? 1.2 : 1.0;
    const height = iframeRef.current
      ? iframeRef.current.offsetWidth * 0.5625
      : defaultHeight;
    return setVideoHeight(Math.floor(height * ratio));
  }, []);

  useEffect(() => {
    window.addEventListener("resize", handleChangeVideoWidth);
    return function cleanup() {
      window.removeEventListener("resize", handleChangeVideoWidth);
    };
  }, [handleChangeVideoWidth]);

  return (
    <iframe
      ref={iframeRef}
      title={title}
      width="90%"
      style={{ marginLeft: "7%" }}
      height={`${videoHeight}px`}
      src={`https://www.youtube.com/embed/${videoId}`}
      frameBorder="0"
      allowFullScreen
    />
  );
};

const YoutubeChannelConsumer = () => {
  const [videos, setVideos] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    (async () => {
      if (channelId) {
        try {
          const data = await fetch(`${baseUrl}${channelId}`).then((response) =>
            response.json()
          );
          setVideos(data.items);

          console.log("YOUTUBE");
          console.log(data.items);
        } catch (error) {
          console.log(error);
        }
      }
    })();
  }, []);

  const sliderSettings = {
    autoplay: true,
    dots: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <section
      style={{
        backgroundColor: "white",
        paddingLeft: "10px",
        paddingRight: "24px",
      }}
    >
      <div className="container">
        <div className="row">
          <HeadingSection title={t("youtube-channel")} />
        </div>
        <div className="row mt-10">
          <div className="col-md-10 offset-md-1 col-sm-6 offset-sm-3">
            <Slider {...sliderSettings}>
              {videos.map((item, index) => (
                <div key={`yt-${item.guid}-${index}`}>
                  <YoutubeVideo
                    videoId={item.guid.split(":")[2]}
                    title={item.title}
                  />
                </div>
              ))}
            </Slider>
          </div>
        </div>
        <div className="row mt-20">
          <div
            className="col-lg-12"
            style={{
              textAlign: "right",
            }}
          >
            <a
              href="https://www.youtube.com/channel/UCuz6zURV0tx0il1OdEgW21A"
              className="btn btn-color btn-xs btn-default"
            >
              <span>
                {t("youtube-see-more")}
                <i className="icofont icofont-arrow-right"></i>
              </span>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default YoutubeChannelConsumer;
