import React, { useEffect, useState, createRef } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { useLax } from "../../helpers/use-lax";
import Loader from "../../components/Loader/Loader";
import HeaderSection from "../../components/mmc/Header";
import { useTranslation } from "react-i18next";
import EventsShowcase from "../../components/mmc/EventsShowcase";

const EventsPage = () => {
  const { t, i18n } = useTranslation();
  useLax();

  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  return (
    <Loader>
      <HeaderSection type={"white"} />
      <div className="container">
        <hr className="mt-20" />
        <EventsShowcase />
        <hr className="mt-100" />
      </div>
    </Loader>
  );
};

export default EventsPage;
