import React, { useRef, useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

import HeaderSection from "../../components/mmc/Header";
import AboutSection from "../../components/mmc/AboutSection";
import HistorySection from "../../components/mmc/HistorySection";
import PatronsSection from "../../components/mmc/PatronsSection";
import ProtectorsSection from "../../components/mmc/ProtectorsSection";
import ContactForms from "../../components/mmc/ContactForm";

const AboutPage = () => {
  const about = useRef();
  const service = useRef();
  const project = useRef();

  const scrollToSection = (e, content) => {
    e.preventDefault();
    switch (content) {
      case "about":
        about.current.scrollIntoView({ behavior: "smooth" });
        break;
      case "service":
        service.current.scrollIntoView({ behavior: "smooth" });
        break;
      case "project":
        project.current.scrollIntoView({ behavior: "smooth" });
        break;
      default:
    }
  };

  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  return (
    <React.Fragment>
      <HeaderSection scrollToSection={scrollToSection} type="white" />
      <hr className="mt-30" />
      <AboutSection ref={about} />
      <PatronsSection />
      <ProtectorsSection />
      <HistorySection title="Nuestra Historia" ref={service} />
      <ContactForms />
    </React.Fragment>
  );
};

export default AboutPage;
