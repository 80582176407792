import React from "react";
import logoWhite from "../../assets/images/mmc-logo-big.png";
import logoBlack from "../../assets/images/mmc-logo-big.png";
import logoText from "../../assets/images/mmc-text.png";

const MainLogo = ({ collapse, showMenu }) => {
  return (
    <div className="navbar-header">
      <div className="logo">
        <a href="/">
          <img
            style={{ display: "inline-block" }}
            className="logo logo-scrolled"
            src={logoBlack}
            alt=""
          />
          <img
            style={{ display: "inline-block" }}
            className="mmc-text"
            src={logoText}
            alt=""
          />
        </a>
      </div>
    </div>
  );
};

export default MainLogo;
