import React, { useRef } from "react";
import servicesData from "../../data/Services/services-architecture.json";
import HeadingSection from "../HeadingSection/HeadingSection";
import Icofont from "react-icofont";

const historyFragments = [
  "Los inicios del Movimiento Matrimonial Católico tiene lugar en la iglesia St. Angela Merici, de la arquidiocesis de NY, en el Bronx, por la necesidad de una pastoral matrimonial parroquial.  Los primeros esfuerzos para esta pastoral fue la preparacion de parejas hispanas para el sacramento del Matrimonio, lograndose con esto que numerosas parejas recibieran el sacramento con la debida preparacion, ya que muchos necesitaban recibir otros sacramentos, tales como Bautismo, confirmacion y primera comununion. A partir de ese momento todas las parejas que iban a recibir el Sacramento del Matrimonio primero pasaban pore se proceso de formacion.",
  "Esto genero otras necesidades que debíamos atender, como el seguimiento en el crecimiento de la fe del matrimonio y la familia.",
  "En los inicios nos reuniamos unas 5 parejas a rezar el rosario cada semana y de ahi fuimos viendo la necesidad de hacer algo exclusivo para las parejas y surgio un grupo de parejas que se reunia a dialogar temas relacionados con la vida matrimonial.",
  "En Mayo del 2000, en la iglesia St. Angela Merici, los esposos Maximo y Marcia Correa, formalmente fundan el Movimiento Matrimonial Católico, bajo la direccion spiritual del Padre Peter Mushi, AJ. El MMC forma parte de los movimientos laicos de la arquidioces de NY desde el año 2014.",
  "La reunión de grupo: Es el punto de encuentro donde las parejas comparten temas relacionados al crecimiento de su vida espiritual, matrimonial y formativo y ayuda a la capacitación continua de los miembros del grupo para mantener un clima de superación constante.",
  "Además de la reunión mensual, el MMC ofrece a las parejas retiros de fin de semana, y otras actividades de crecimiento espiritual como: La misa del Dia Mundial del Matrimono, se celebra cada Febrero en la Catedral St. Patricio. Misa de la sagrada famila, en el mes de Diciembre. Misa a San Juan Pablo II, en el mes de Octubre.",
  "El congreso de Parejas Unidos en el Amor, una iniciativa del MMC, que se celebra cada año, abierto a todas las parejas, desde el año 2008. A partir del 2012 hasta 2017, el congreso se realizó con la colaboración de la oficina de Vida Familiar y respeto a la vida, en la persona de Denise D’Oleo, directora de los programas en Español. Denise D’ Oleo, fue una gran colaboradora y de gran apoyo para el Movimiento Matrimonial Católico.",
  "La caminata Creemos en Matrimonio, iniciada desde el 2017 en el Parque Central de NY, es una actividad donde caminamos tomados de la mano como parjea, para dar testimonio del verdadero matrimonio creado por Dios.",
  "Como fruto del apostolado del Movimiento Matrimonial Católico, cada año unas 10 a 12 parejas miembros del MMC, reciben el sacramento del matrimonio.",
  "Además de las actividades espirituales el MMC, se realizan actividades recreativas para la pareja y la familia. Entre estas actividades tenemos el día del parque en familia, noche de parejas y la fiesta de navidad de los niños.",
  "Habiendo iniciado 5 parejas, a este momento el MMC cuenta con más de 250 parejas, que provienen de diferentes países y de diferentes edades. Somos actualmente, 21 grupos y estamos establecidos en varias parroquias de la arquidiócesis de NY.",
];

const HistorySection = ({ title, tagline, ref, ...otherProps }) => {
  return (
    <section id="service" className="dark-bg" ref={ref}>
      <div className="container">
        <div className="row">
          <HeadingSection
            title={title}
            tagline={tagline}
            classAppend="white-color"
          />
        </div>
        <div className="row mt-50">
          {historyFragments.map((fragment, i) => {
            return (
              <div
                key={`fragment-${i}`}
                data-aos={"fade-up"}
                data-aos-delay={`${i}00`}
                data-aos-duration={700}
                className="feature-box mb-50"
                style={{
                  textAlign: "justify",
                }}
              >
                <p className="white-color">{fragment}</p>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default HistorySection;
