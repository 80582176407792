import React from "react";
import Icofont from "react-icofont";
import logoFooter from "../../assets/images/mmc-text-white.png";

const dataSocial = [
  {
    id: 1,
    icon: "facebook",
    portfolio: 1,
    footer: 1,
    link: "!#",
  },
  {
    id: 2,
    icon: "dribbble",
    portfolio: 0,
    footer: 1,
    link: "!#",
  },
  {
    id: 3,
    icon: "behance",
    portfolio: 1,
    footer: 1,
    link: "!#",
  },
  {
    id: 4,
    icon: "instagram",
    portfolio: 0,
    footer: 1,
    link: "!#",
  },
  {
    id: 5,
    icon: "linkedin",
    portfolio: 1,
    footer: 1,
    link: "!#",
  },
  {
    id: 6,
    icon: "pinterest",
    portfolio: 1,
    footer: 0,
    link: "!#",
  },
  {
    id: 7,
    icon: "twitter",
    portfolio: 1,
    footer: 0,
    link: "!#",
  },
];

const FooterOne = () => (
  <>
    <footer className="footer" id="footer-fixed">
      <div className="footer-main">
        <div className="container">
          <div className="row">
            <div className="col-sm-8 offset-sm-2 col-md-8 offset-md-2 col-lg-6 offset-lg-3">
              <div className="widget widget-text">
                <div className="logo logo-footer">
                  <a href={`${process.env.PUBLIC_URL}/`}>
                    <img
                      className="logo logo-display"
                      src={logoFooter}
                      alt=""
                    />
                  </a>
                </div>
                <p>
                  Somos un movimiento Laico con sede en Estados Unidos De Norte
                  América en el estado de New York, abierto para todas las
                  parejas católicas estén o no casados por la iglesia que
                  quieran crecer como parejas junto a Jesús y estén dispuestos a
                  compartir su experiencia con otras parejas.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-copyright">
        <div className="container">
          <div className="row">
            <div className="col-md-6 offset-md-5">
              <div className="copy-right">
                © 2022 MMC. Desarrollado por{" "}
                <b>
                  {" "}
                  <a href="#"> NAS Software</a>
                </b>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
    <div className="footer-height" style={{ height: "463px" }}></div>
  </>
);

export default FooterOne;
