import React, { useState } from "react";
import Icofont from "react-icofont";
import Modal from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";

import patronImage from "../../assets/images/mmc-patron.png";

const PatronsSection = ({ ref, ...otherProps }) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <Modal
        show={isOpen}
        onHide={() => {
          setIsOpen(!setIsOpen);
        }}
        style={{
          color: "#333",
          backgroundColor: "rgba(0, 0, 0, 0.3)",
          borderRadius: 5,
        }}
        className="black-color"
        size="lg"
        centered
      >
        <Modal.Body
          style={{
            backgroundColor: "#F5F3F1",
            textAlign: "justify",
          }}
        >
          <h3 className="text-uppercase font-400 black-color">
            San Juan Pablo II
          </h3>
          <div className="mt-30">
            <p>
              (Karol Wojtyla; Wadowice, Cracovia, 1920 - Roma, 2005) Papa de la
              Iglesia católica (1978-2005). Elegido para el solio de Pedro en
              octubre de 1978, cuando ocupaba el puesto de cardenal-arzobispo de
              Cracovia, el prelado polaco Karol Wojtyla fue el primer pontífice
              no italiano en más de cuatro siglos.
            </p>
          </div>
          <div className="mt-30">
            <p>
              Hijo de un oficial de la administración del ejército polaco y de
              una maestra de escuela, de joven practicó el atletismo, el fútbol
              y la natación; fue también un alumno excelente y presidió diversos
              grupos estudiantiles. Desarrolló además una gran pasión por el
              teatro, y durante algún tiempo aspiró a estudiar literatura y
              convertirse en actor profesional.
            </p>
          </div>
          <div className="mt-30">
            <p>
              Fue ordenado sacerdote (1 de Noviembre de 1946), amplió sus
              estudios en Roma y obtuvo el doctorado en teología en el Pontifico
              Ateneo Angelicum. En 1967 el Papa Pablo VI lo nombró cardenal, y
              el 16 de Octubre de 1978, a la edad de cincuenta y ocho años, fue
              elegido para suceder al Papa Juan Pablo I, fallecido tras treinta
              y cuatro días de pontificado. De este modo se convirtió en el
              primer Papa no italiano desde 1523, y en el primero procedente de
              un país del bloque comunista.
            </p>
          </div>
          <div className="mt-30">
            <p>
              Desde sus primeras encíclicas, Redemptoris Hominis (1979) y Dives
              in misericordia (1980), Juan Pablo II exaltó el papel de la
              iglesia como maestra de los hombres y destacó la necesidad de una
              fé robusta, arraigada en el patrimonio teológico tradicional, y de
              una sólida moral, sin mengua de una apertura cristiana al mundo
              del siglo XX. Denunció la teología de la Liberación, criticó la
              relajación moral y proclamó la unidad espiritual de Europa.
            </p>
          </div>
          <div className="mt-30">
            <p>
              Entre sus encíclicas cabe mencionar Laborem exercens (El hombre en
              su trabajo, 1981), Redemptoris mater (La madre del Redentor,
              1987), Sollicitudo rei socialis(La preocupación social, 1987),
              Redemptoris missio (La misión del Redentor, 1990) y Centessimus
              annus (El centenario, 1991). Entre sus exhortaciones y cartas
              apostólicas destacan Catechesi tradendae (Sobre la catequesis,
              hoy, 1979), Familiaris consortio (La familia, 1981), Salvifici
              doloris(El dolor salvífico, 1984), Reconciliato et paenitentia
              (Reconciliación y penitencia, 1984), Mulieris dignitatem (La
              dignidad de la mujer, 1988), Christifidelis laici (Los fieles
              cristianos, 1988) y Redemptoris custos (El custodio del Redentor,
              1989). En Evangelium vitae (1995) trató las cuestiones del aborto,
              las técnicas de reproducción asistida y la eutanasia. Ut unum sint
              (Que todos sean uno, 1995) fue la primera encíclica de la historia
              dedicada al ecumenismo. En 1994 publicó el libro Cruzando el
              umbral de la esperanza.
            </p>
          </div>
          <div className="mt-30">
            <p>
              El pontificado de Juan Pablo II no estuvo exento de polémica. Su
              talante tradicional le llevó a sostener algunos enfoques
              característicos del catolicismo conservador, sobre todo en lo
              referente a la prohibición del aborto y los anticonceptivos, la
              condena del divorcio y la negativa a que las mujeres se incorporen
              al sacerdocio. Sin embargo, también fue un gran defensor de la
              justicia social y económica, abogando en todo momento por la
              mejora de las condiciones de vida en los países más pobres del
              mundo.
            </p>
          </div>
          <div className="mt-30">
            <p>
              Juan Pablo II falleció el 2 de abril de 2005. Su desaparición
              significó para algunos la pérdida de uno de los líderes más
              carismáticos de la historia reciente; para otros implicó la
              posibilidad de imaginar una Iglesia católica más acorde a la
              sociedad moderna. En cualquier caso, su muerte ocurrió en un
              momento de revisionismo en el seno de la institución, de una
              evaluación sobre el protagonismo que ha de tener en el mundo
              contemporáneo y el que pretende tener en el del futuro. Su
              sucesor, Benedicto XVI, anunció ese mismo año el inicio del
              proceso para la beatificación de Juan Pablo II, que tuvo lugar el
              1 de mayo de 2011. El 27 de abril de 2014 fue canonizado, junto
              con Juan XIII, en una ceremonia oficiada por el papa Francisco,
              que había sido elegido pontífice en marzo de 2013, tras la
              renuncia de Benedicto XVI. Festividad Memoria: 22 de octubre
              (fecha del inicio de su Pontificado, en 1978).
            </p>
          </div>
        </Modal.Body>
      </Modal>
      <div className="white-bg clearfix pt-70 pb-70 relative row" id="patrons">
        <div className="col-md-6 pl-20 mb-20" style={{ textAlign: "justify" }}>
          <h2 className="text-uppercase font-700 black-color pl-20 pr-20">
            Nuestro Patrono
          </h2>
          <h3 className="text-uppercase font-400 black-color pl-20 pr-20">
            San Juan Pablo II
          </h3>
          <div className="mt-30 pl-20 pr-20">
            <p>
              (Karol Wojtyla; Wadowice, Cracovia, 1920 - Roma, 2005) Papa de la
              Iglesia católica (1978-2005). Elegido para el solio de Pedro en
              octubre de 1978, cuando ocupaba el puesto de cardenal-arzobispo de
              Cracovia, el prelado polaco Karol Wojtyla fue el primer pontífice
              no italiano en más de cuatro siglos.
            </p>
            <p>
              Hijo de un oficial de la administración del Ejército polaco y de
              una maestra de escuela...
            </p>
          </div>
          <div className="mt-30 pl-20 pr-20">
            <a
              href="#patrons"
              className="btn btn-color btn-circle btn-animate"
              onClick={() => {
                setIsOpen(true);
              }}
            >
              <span>{t("see-more")}</span>
            </a>
          </div>
        </div>
        <div className="white-bg col-md-6 col-sm-12">
          <img
            src={patronImage}
            style={{ width: "100%", height: "auto", objectFit: "cover" }}
            alt=""
          />
        </div>
      </div>
    </>
  );
};

export default PatronsSection;
