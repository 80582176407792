import React, { useEffect, useState } from "react";
import HeadingSection from "./HeadingSection";
import TeamMember from "./TeamMember";
import { useTranslation } from "react-i18next";

import useBreakpoints from "../../helpers/useBreakpoints";
import Slider from "react-slick";
import { t } from "i18next";

const OurTeam = () => {
  const currentBreakpoint = useBreakpoints();
  const { t } = useTranslation();
  const isMobile = ["sm", "xs"].includes(currentBreakpoint);
  const [isLoading, setIsLoading] = useState(true);
  const [teamData, setTeamData] = useState([]);
  const [teamDataSecondRow, setTeamDataSecondRow] = useState([]);

  const FIXED_TEAM_DATA_FIRST_ROW = [
    {
      name: "Máximo y Marcia Correa",
      role: "Fundadores y coordinadores del MMC",
      image: "maximo_marcia.jpg",
    },
    {
      name: "Rev. Peter Mushi, AJ",
      role: "Director espiritual del MMC",
      image: "team-member-rev-peter.jpg",
    },
  ];
  const FIXED_TEAM_DATA_SECOND_ROW = [
    {
      name: "Alexis y Kelodys Rodriguez",
      role: t("Servidores"),
      image: "ALEXIS_Y_KELODYS-RODRIGUEZ.jpg",
    },
    {
      name: "Bienvenido y Clara Herrera",
      role: t("Servidores"),
      image: "BIENVENIDO_Y_CLARA-HERRERA.jpg",
    },
    {
      name: "Daniel y Ramona Abreu",
      role: t("Servidores"),
      image: "DANIEL_Y_RAMONA_ABREU.jpg",
    },
    {
      name: "David y Carmen Diaz",
      role: t("Servidores"),
      image: "DAVID_Y_CARMEN-DIAZ.jpg",
    },
    {
      name: "Eduardo y Yovianqui Rodriguez",
      role: t("Servidores"),
      image: "EDUARDO_Y_YOVIANQUI-RODRIGUEZ.jpg",
    },
    {
      name: "Isidro y Antonia Mora",
      role: t("Servidores"),
      image: "ISIDRO_Y_ANTONIA-MORA.jpg",
    },
    {
      name: "Jose y Denise Germosen",
      role: t("Servidores"),
      image: "JOSE_Y_DENISE_GERMOSEN.jpg",
    },
    {
      name: "Jose y Dominga Rodriguez",
      role: t("Servidores"),
      image: "JOSE_Y_DOMINGA-RODRIGUEZ.jpg",
    },
    {
      name: "Milton y Ada Fernandez",
      role: t("Servidores"),
      image: "MILTON_Y_ADA_FERNANDEZ.jpg",
    },
    {
      name: "Rafael y Ingrid Hidalgo",
      role: t("Servidores"),
      image: "RAFAEL_&_INGRID_HIDALGO.jpg",
    },
    {
      name: "Rafael y Nuris Polanco",
      role: t("Servidores"),
      image: "RAFAEL_Y_NURIS_POLANCO.jpg",
    },
    {
      name: "Ramon y Maria Hernandez ",
      role: t("Servidores"),
      image: "RAMON_Y_MARIA.jpg",
    },{
      name: "Victor e Ivelisse Ceballos",
      role: t("Servidores"),
      image: "VICTOR_E_IVELISSE_CEBALLOS.jpg",
    },
    ,{
      name: "Roberto E Indira Boitel",
      role: t("Servidores"),
      image: "Roberto_E_Indira_Boitel.jpg",
    },
  ];

  useEffect(() => {
    setTimeout(() => {
      setTeamData(FIXED_TEAM_DATA_FIRST_ROW);
      setTeamDataSecondRow(FIXED_TEAM_DATA_SECOND_ROW);
      // TODO: Read data from API
      setIsLoading(false);
    }, 3000);
  }, []);

  const responsiveBreakpoints = (dataArray) => {
    return [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: Math.min(4, dataArray.length),
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: Math.min(2, dataArray.length),
        },
      },
      {
        breakpoint: 501,
        settings: {
          slidesToShow: Math.min(1, dataArray.length),
        },
      },
    ];
  };

  const firstRowSliderSettings = {
    className: "member",
    slidesToShow: Math.min(2, FIXED_TEAM_DATA_FIRST_ROW.length),
    arrows: true,
    autoplay: true,
    responsive: [...responsiveBreakpoints(FIXED_TEAM_DATA_FIRST_ROW)],
  };

  const secondRowSliderSettings = {
    className: "member",
    slidesToShow: Math.min(4, FIXED_TEAM_DATA_SECOND_ROW.length),
    arrows: true,
    autoplay: true,
    responsive: [...responsiveBreakpoints(FIXED_TEAM_DATA_SECOND_ROW)],
  };

  return (
    <section className={"pb-0"} style={{ backgroundColor: "#F2E7D5" }}>
      <div className="row">
        <HeadingSection title={t("team-title")} />
      </div>
      <div className="container" >
        <div
          className="row remove-padding team-slider"
          //
          // style={{ width: "80vw", marginLeft: "5%" }}
        >
          <Slider {...firstRowSliderSettings}>
            {isLoading
              ? [...Array(2).keys()].map((item, i) => {
                  return <TeamMember key={`member-skeleton-${i}`} isLoading />;
                })
              : teamData.map((member, i) => {
                  return (
                    <TeamMember
                      key={`team-member-${i}`}
                      name={member.name}
                      role={member.role}
                      image={member.image}
                      removePadding="true"
                    />
                  );
                })}
          </Slider>
        </div>
        <div
          className="row remove-padding"
          style={{ width: "80vw", marginLeft: "5%" }}
        >
          {!isLoading && (
            <Slider {...secondRowSliderSettings}>
              {teamDataSecondRow.map((member, i) => {
                return (
                  <TeamMember
                    key={`team-member-${i}`}
                    name={member.name}
                    role={member.role}
                    image={member.image}
                    removePadding="true"
                  />
                );
              })}
            </Slider>
          )}
        </div>
      </div>
    </section>
  );
};

export default OurTeam;
