import useBreakpoint from "use-breakpoint";

const BREAKPOINTS = {
  xxl: 1400,
  xl: 1200,
  lg: 992,
  md: 768,
  sm: 576,
  xs: 0,
};

export default () => {
  const { breakpoint } = useBreakpoint(BREAKPOINTS);
  return breakpoint;
};
