import React, { useRef, useState } from "react";
import Loader from "../Loader/Loader";
import HeadingSection from "./HeadingSection";
import { useTranslation } from "react-i18next";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import Icofont from "react-icofont";

const DEFAULT_INPUTS = {
  email: "",
  phone: "",
  husband_name: "",
  wife_name: "",
  city: "",
  message: "",
};

const ContactForms = ({ isFromNavbar, ...props }) => {
  const { t } = useTranslation();
  const [isDialogVisible, setDialogVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [inputs, setInputs] = useState(DEFAULT_INPUTS);
  const ref = useRef();

  const sendMessage = async () => {
    setIsLoading(true);

    try {
      await axios.post("/contactform/send", {
        email: inputs.email,
        phone: inputs.phone,
        husband_name: inputs.husband_name,
        wife_name: inputs.wife_name,
        city: inputs.city,
        message: inputs.message,
      });
    } catch (err) {
      console.error(err);
    }

    setInputs(DEFAULT_INPUTS);
    setDialogVisible(true);
  };

  const cleanInputs = () => {
    setInputs(DEFAULT_INPUTS);
    setIsLoading(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInputs({ ...inputs, [name]: value });
  };

  return (
    <Loader>
      <Modal
        show={isDialogVisible}
        onHide={() => {
          setDialogVisible(false);
          cleanInputs();
        }}
        centered
      >
        <Modal.Body
          style={{
            borderRadius: "5px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            fontSize: "1.5em",
          }}
        >
          <span className="black-color">
            {t("message-subsmission-response")}
          </span>
          <span
            onClick={() => {
              setDialogVisible(false);
              cleanInputs();
            }}
            style={{ textAlign: "center", color: "#8B0000", fontSize: "2em" }}
          >
            <Icofont icon="close" />
          </span>
        </Modal.Body>
      </Modal>
      <section className="contact-us" id="contact" ref={ref}>
        <div className="container">
          <div className="row">
            <HeadingSection title={t("contact-us")}>
              {!isFromNavbar && t("contact-form-tagline")}
            </HeadingSection>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <form
                name="contact-form"
                id="contact-form"
                className="app-form"
                onSubmit={(e) => {
                  e.preventDefault();
                  console.log(inputs);
                  sendMessage();
                }}
              >
                <div className="messages"></div>
                <div className="row">
                  <div
                    className="col-md-6 col-sm-6"
                    data-aos={"fade-up"}
                    data-aos-delay={100}
                    data-aos-duration={700}
                  >
                    <div className="form-floating">
                      <input
                        type="text"
                        name="husband_name"
                        className="form-control"
                        id="husband_name"
                        required="required"
                        placeholder={t("form-husband-placeholder")}
                        data-error={t("required-field-message")}
                        value={inputs.husband_name}
                        onChange={handleInputChange}
                      />
                      <label htmlFor="husband_name">{t("husband-name")}</label>
                      <div className="help-block with-errors mt-20"></div>
                    </div>
                  </div>
                  <div
                    className="col-md-6 col-sm-6"
                    data-aos={"fade-up"}
                    data-aos-delay={100}
                    data-aos-duration={700}
                  >
                    <div className="form-floating">
                      <input
                        type="text"
                        name="wife_name"
                        className="form-control"
                        id="wife_name"
                        required="required"
                        placeholder={t("form-wife-placeholder")}
                        data-error={t("required-field-message")}
                        value={inputs.wife_name}
                        onChange={handleInputChange}
                      />
                      <label htmlFor="wife_name">{t("wife-name")}</label>
                      <div className="help-block with-errors mt-20"></div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div
                    className="col-lg-4 col-md-4 col-sm-12"
                    data-aos={"fade-up"}
                    data-aos-delay={100}
                    data-aos-duration={700}
                  >
                    <div className="form-floating">
                      <input
                        type="email"
                        name="email"
                        className="form-control"
                        id="email"
                        required="required"
                        placeholder={t("form-email-placeholder")}
                        data-error={t("required-field-message")}
                        value={inputs.email}
                        onChange={handleInputChange}
                      />
                      <label htmlFor="email">{t("email")}</label>
                      <div className="help-block with-errors mt-20"></div>
                    </div>
                  </div>
                  <div
                    className="col-lg-4 col-md-4 col-sm-12"
                    data-aos={"fade-up"}
                    data-aos-delay={100}
                    data-aos-duration={700}
                  >
                    <div className="form-floating">
                      <input
                        type="text"
                        name="phone"
                        className="form-control"
                        id="phone"
                        required="required"
                        placeholder={t("form-phone-placeholder")}
                        data-error={t("required-field-message")}
                        value={inputs.phone}
                        onChange={handleInputChange}
                      />
                      <label htmlFor="phone">{t("phone")}</label>
                      <div className="help-block with-errors mt-20"></div>
                    </div>
                  </div>
                  <div
                    className="col-lg-4 col-md-4 col-sm-12"
                    data-aos={"fade-up"}
                    data-aos-delay={100}
                    data-aos-duration={700}
                  >
                    <div className="form-floating">
                      <input
                        type="text"
                        name="city"
                        className="form-control"
                        id="city"
                        required="required"
                        placeholder={t("form-city-placeholder")}
                        data-error={t("required-field-message")}
                        value={inputs.city}
                        onChange={handleInputChange}
                      />
                      <label htmlFor="city">{t("city")}</label>
                      <div className="help-block with-errors mt-20"></div>
                    </div>
                  </div>
                </div>
                <div
                  className="form-floating"
                  data-aos={"fade-up"}
                  data-aos-delay={100}
                  data-aos-duration={700}
                >
                  <textarea
                    name="message"
                    className="form-control"
                    id="message"
                    rows="7"
                    placeholder={t("form-message-placeholder")}
                    required
                    data-error={t("form-message-required")}
                    value={inputs.message}
                    onChange={handleInputChange}
                  ></textarea>
                  <label htmlFor="message">{t("form-message-contact")}</label>
                  <div className="help-block with-errors mt-20"></div>
                </div>
                <div className="text-center">
                  <button
                    type="submit"
                    name="submit"
                    className="btn btn-color btn-circle"
                    disabled={isLoading}
                  >
                    {isLoading ? ". . ." : t("submit-form-message")}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </Loader>
  );
};

export default ContactForms;
