import React from "react";
import PropTypes from "prop-types";
import parse from "html-react-parser";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

BlogPost.propTypes = {
  post: PropTypes.object,
};

function BlogPost({ key, post, ...props }) {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <div
      className="post"
      onClick={() => {
        history.push(`${process.env.PUBLIC_URL}/blog/${post.hashid}`);
      }}
      style={{
        cursor: "pointer",
      }}
    >
      <div className="post-img">
        <img
          className="img-fluid"
          src={post.thumbnail}
          alt=""
          style={{
            height: "350px",
          }}
        />
      </div>
      <div className="post-info">
        <h3>
          <a href={`${process.env.PUBLIC_URL}/blog/${post.hashid}`}>
            {post.title}
          </a>
        </h3>
        {/* Published date */}
        {/* <h6>{post.published}</h6> */}
        <p>{parse(post.summary || "")}</p>
        <a
          className="readmore black-text"
          href={`${process.env.PUBLIC_URL}/blog/${post.hashid}`}
        >
          <span>{t("read-more")}</span>
        </a>
      </div>
    </div>
  );
}

export default BlogPost;
