import React, { useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Lazy } from "swiper";
import "swiper/swiper.min.css";
import "swiper/components/effect-fade/effect-fade.min.css";
import "swiper/components/navigation/navigation.min.css";
import "swiper/components/pagination/pagination.min.css";
import parse from "html-react-parser";
import Icofont from "react-icofont";
import SwiperCore, {
  EffectFade,
  Navigation,
  Autoplay,
  Pagination,
} from "swiper/core";
import { motion } from "framer-motion/dist/framer-motion";
import { useTranslation } from "react-i18next";
import axios from "axios";

SwiperCore.use([EffectFade, Navigation, Autoplay, Pagination, Lazy]);

const FIXED_CAROUSEL_DATA = [
  {
    title: "Este es un titulo",
    tagline: "De prueba",
    text: "Otro texto de prueba 1",
    image:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/2/24/Almeja_gigante_%28Tridacna_maxima%29%2C_Temple%2C_Sharm_el-Sheij%2C_Egipto%2C_2022-03-26%2C_DD_33.jpg/1024px-Almeja_gigante_%28Tridacna_maxima%29%2C_Temple%2C_Sharm_el-Sheij%2C_Egipto%2C_2022-03-26%2C_DD_33.jpg",
  },
  {
    title: "Este es un titulo (2)",
    tagline: "De prueba 2",
    image:
      "https://upload.wikimedia.org/wikipedia/commons/b/bb/David-Oath_of_the_Horatii-1784.jpg",
  },
  {
    title: "Este es un titulo (tercero y ultimo)",
    tagline: "De prueba (3)",
    text: "Otro texto de prueba 3",
    image:
      "https://upload.wikimedia.org/wikipedia/commons/2/2e/Villa_Rotonda_front.jpg",
  },
];

const HeroCarousel = () => {
  const { t } = useTranslation();
  const ref = useRef(null);
  const [isLoading, setIsLoading] = useState(true);
  const [carouselData, setCarouselData] = useState([]);

  useState(() => {
    setTimeout(async () => {
      const response = await axios.get("/announcements/active");
      setCarouselData(response.data.data);
      setIsLoading(false);

      console.log("CAROUSEL");
      console.log(response.data.data);
    }, 3000);
  }, []);

  return (
    <section className="pt-50 pb-0" id="home" ref={ref}>
      <div className="slider-bg flexslider">
        <ul className="slides">
          <Swiper
            navigation={!isLoading}
            pagination={{ clickable: true }}
            loop={true}
            grabCursor={true}
            effect={"fade"}
            autoplay={{ delay: 7000 }}
            lazy={!isLoading}
          >
            {isLoading ? (
              <SwiperSlide key={`loading-slide-id`}>
                <div className="hero-text-wrap">
                  <div className="hero-text white-color">
                    <div className="container text-center">
                      <motion.div
                        animate={{
                          scale: [1, 2, 2, 1, 1],
                          rotate: [0, 90, 180, 270, 360],
                        }}
                        transition={{
                          duration: "2",
                          repeat: Infinity,
                        }}
                      >
                        <h1>
                          <Icofont icon="refresh" />
                        </h1>
                      </motion.div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            ) : (
              carouselData.map((slide, index) => (
                <SwiperSlide key={slide.hashid}>
                  <div
                    className="slide-img"
                    style={{
                      background: `url(${slide.image}) center center / cover scroll no-repeat`,
                    }}
                  ></div>
                  {/* <div
                    className={
                      "hero-text-wrap " +
                      (slide.bg ? "gradient-overlay-bg" : "")
                    }
                  >
                    <div className="hero-text white-color">
                      <div className="container text-center">
                        <h1 className="white-color text-uppercase font-700">
                          {slide.title}
                        </h1>
                        <h2 className="white-color font-500 letter-spacing-5">
                          {slide.body || ""}
                        </h2>
                        <h3 className="white-color font-400">
                          {slide.body ? parse(slide.body) : ""}
                        </h3>
                        <p className="text-center mt-30">
                          <a
                            href={slide.url}
                            className="btn btn-animate btn-color btn-square"
                          >
                            <span>
                              {t("carousel-see-more")}
                              <i className="icofont icofont-arrow-right"></i>
                            </span>
                          </a>
                        </p>
                      </div>
                    </div>
                  </div> */}
                </SwiperSlide>
              ))
            )}
          </Swiper>
        </ul>
      </div>
    </section>
  );
};

export default HeroCarousel;
