import React, { useState, useEffect, useCallback } from "react";
import Icofont from "react-icofont";
import { useTranslation } from "react-i18next";
import Marquee from "react-fast-marquee";
import Modal from "react-bootstrap/Modal";

import ContactForm from "../../components/mmc/ContactForm";
import DropdownMenu from "./DropdownMenu";
import MainLogo from "./MainLogo";

const marqueePhrases = [
  "1, 2, 3... ¡Creemos en el matrimonio!",
  "Familia que reza unida permanece unida",
];

const Header = ({ type }) => {
  const { t } = useTranslation();
  const [fixed, setFixed] = useState(false);
  const [collapse, setCollapse] = useState(false);
  const [currentPhraseIndex, setCurrentPhraseIndex] = useState(0);
  const [isShowingModal, setModalVisibility] = useState(false);
  const [isShowingFlyer, setFlyerVisibility] = useState(false);

  const dataNav = [
    {
      id: 1,
      title: t("article-title"),
      link: "/blog",
    },
    {
      id: 2,
      title: t("about-us"),
      link: "/about-us",
    },
    {
      id: 3,
      title: t("retreats"),
      link: "/retreats",
    },
    {
      id: 4,
      title: t("events"),
      link: "/events",
    },
  ];

  const showMenu = () => {
    setCollapse(!collapse);
    const menu = document.getElementById("navbar-menu");
    collapse ? menu.classList.remove("in") : menu.classList.add("in");
  };

  const handleScroll = useCallback(() => {
    if (window.pageYOffset > 34) {
      setFixed(true);
    } else {
      setFixed(false);
    }
  }, []);

  useEffect(() => {
    // window.addEventListener("resize", resizeForm);
    window.addEventListener("scroll", handleScroll);

    return () => {
      // window.removeEventListener("resize", resizeForm);
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  const showDialog = () => {
    setModalVisibility(true);
  };

  const showFlyer = () => {
    setFlyerVisibility(true);
  };

  return (
    <nav
      className={
        "navbar navbar-expand-lg navbar-light navbar-fixed white bootsnav on no-full" +
        (fixed || type === "white" ? "" : "navbar-transparent")
      }
      style={{
        boxShadow: "0 1px 5px rgb(0 0 0 / 0.2)",
      }}
    >
      <Modal
        show={isShowingModal}
        onHide={() => {
          setModalVisibility(false);
        }}
        style={{
          color: "#333",
          backgroundColor: "rgba(0, 0, 0, 0.3)",
        }}
        className="black-color"
        fullscreen
      >
        <Modal.Header
          style={{
            backgroundColor: "#F5F3F1",
            display: "flex",
            flexDirection: "row",
            justifyContent: "end",
            alignItems: "center",
            color: "#8B0000",
          }}
          title="Proyecto Casa de Retiro del Movimiento Matrimonial Catolico"
        >
          
          <span
            className=""
            onClick={(e) => {
              setModalVisibility(false);
            }}
          >
            <Icofont icon="close" size="2" />
          </span>
        </Modal.Header>
        <Modal.Body
          style={{
            backgroundColor: "#F5F3F1",
          }}
        >
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12" style={{textAlign: "center"}}>
              <h4>Proyecto Casa de Retiro del Movimiento Matrimonial Catolico</h4>
              <p>Por la Gracia de Dios, el MMC ha iniciado este proyecto para adquirir nuestra propia Casa de Retiro. Porfavor unase para realizar este maravilloso proyecto</p>
              <a style={{fontSize: "14px", color: "white"}} className="btn btn-circle btn-sm default-bg" target="_blank" href="https://secure.myvanco.com/L-Z9Z2/home">Colabora</a>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={isShowingFlyer}
        onHide={() => {
          setFlyerVisibility(false);
        }}
        style={{
          color: "#333",
          backgroundColor: "rgba(0, 0, 0, 0.3)",
        }}
        className="black-color"
        fullscreen
      >
        <Modal.Header
          style={{
            backgroundColor: "#F5F3F1",
            display: "flex",
            flexDirection: "row",
            justifyContent: "end",
            alignItems: "center",
            color: "#8B0000",
          }}
        >
          <span
            className=""
            onClick={(e) => {
              setFlyerVisibility(false);
            }}
          >
            <Icofont icon="close" size="2" />
          </span>
        </Modal.Header>
        <Modal.Body
          style={{
            backgroundColor: "#F5F3F1",
          }}
        >
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12">
              <img src={"flyer_2023.jpeg"} alt="Imagen de Actividad" />
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <div className="container header-container">
        <button
          type="button"
          className={"navbar-toggler " + (collapse ? "collapsed" : "")}
          data-toggle="dropdown"
          data-target="#navbar-menu"
          onClick={showMenu}
        >
          <Icofont icon="navigation-menu" size="2" />
        </button>
        <MainLogo showMenu={showMenu} />
        <DropdownMenu dataNav={dataNav} />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {/* <span
            className="collapse navbar-collapse btn btn-circle btn-sm secondary-bg"
            onClick={(e) => {
              showDialog();
            }}
            style={{
              margin: 0,
              display: "flex",
              justifyContent: "center",
              color: "white",
              fontSize: "13px"
            }}
          >
            <b>{t("collaborate")}</b>
          </span> */}

          <img
              src={require(`../../assets/images/donate2.png`)}
              className="collapse navbar-collapse"
              style={{width: "80px", cursor: "pointer"}}
              onClick={(e) => {
                showDialog();
              }}
          />
          <span
            className="navbar-toggler btn btn-circle secondary-bg"
            onClick={(e) => {
              showDialog();
            }}
          >
            <img
              src={require(`../../assets/images/donate2.png`)}
              className="navbar-toggler navbar-collapse"
              style={{width: "60px", cursor: "pointer"}}
              onClick={(e) => {
                showDialog();
              }}
          />
          </span>
        </div>
      </div>
      <Marquee
        gradient={false}
        loop={0}
        style={{
          height: "50px",
          fontSize: "25px",
          backgroundColor: "darkred",
          color: "white",
          position: "relative",
          top: "2px",
        }}
        speed={60}
        onCycleComplete={() => {
          console.log(
            "New index",
            (currentPhraseIndex + 1) % marqueePhrases.length
          );
          setCurrentPhraseIndex(
            (currentPhraseIndex + 1) % marqueePhrases.length
          );
        }}
      >
        <div
          style={{
            display: "inline",
            width: "120vw",
            backgroundColor: "blue",
          }}
        >
          {" "}
        </div>
        <div
          style={{
            display: "inline",
            textAlign: "center",
          }}
        >
          {marqueePhrases[currentPhraseIndex]}
        </div>
      </Marquee>
    </nav>
  );
};

export default Header;
