import React, { useRef, useState } from "react";
import Loader from "../Loader/Loader";
import HeadingSection from "./HeadingSection";
import { useTranslation } from "react-i18next";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import Icofont from "react-icofont";

const DEFAULT_INPUTS = {
  email: "",
  phone: "",
  name: "",
};

const RetreatForm = ({ retreat, ...props }) => {
  const { t } = useTranslation();
  const [isDialogVisible, setDialogVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [inputs, setInputs] = useState(DEFAULT_INPUTS);
  const ref = useRef();


  const sendMessage = async () => {
    setIsLoading(true);

    try {
      await axios.post(`retreats/${retreat.hashid}/register`, {
        email: inputs.email,
        phone: inputs.phone,
        name: inputs.name
      });
    } catch (err) {
      console.error(err);
    }

    setInputs(DEFAULT_INPUTS);
    setDialogVisible(true);
  };

  const cleanInputs = () => {
    setInputs(DEFAULT_INPUTS);
    setIsLoading(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInputs({ ...inputs, [name]: value });
  };

  return (
    <Loader>
      <Modal
        show={isDialogVisible}
        onHide={() => {
          setDialogVisible(false);
          cleanInputs();
        }}
        centered
      >
        <Modal.Body
          style={{
            borderRadius: "5px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            fontSize: "1.5em",
          }}
        >
          <span className="black-color">
            {t("retreat-response")}
          </span>
          <a target="_blank" href={retreat ? retreat.payment_url: ""}>Pagar Retiro</a>
          <span
            onClick={() => {
              setDialogVisible(false);
              cleanInputs();
            }}
            style={{ textAlign: "center", color: "#8B0000", fontSize: "2em" }}
          >
          </span>
        </Modal.Body>
      </Modal>
      <div className="contact-us" id="contact" ref={ref}>
        <div className="container">
          <div className="row">
            <HeadingSection title={t("retreat-register")}>
            {t("retreat-form-tagline")}
            </HeadingSection>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <form
                name="contact-form"
                id="contact-form"
                className="app-form"
                onSubmit={(e) => {
                  e.preventDefault();
                  sendMessage();
                }}
              >
                <div className="messages"></div>
                <div className="row">
                  <div
                    className="col-md-12 col-sm-12"
                    data-aos={"fade-up"}
                    data-aos-delay={100}
                    data-aos-duration={700}
                  >
                    <div className="form-floating">
                      <input
                        type="text"
                        name="name"
                        className="form-control"
                        id="name"
                        required="required"
                        placeholder={t("name")}
                        data-error={t("required-field-message")}
                        value={inputs.name}
                        onChange={handleInputChange}
                      />
                      <label htmlFor="husband_name">{t("name")}</label>
                      <div className="help-block with-errors mt-20"></div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div
                    className="col-lg-12 col-md-12 col-sm-12"
                    data-aos={"fade-up"}
                    data-aos-delay={100}
                    data-aos-duration={700}
                  >
                    <div className="form-floating">
                      <input
                        type="email"
                        name="email"
                        className="form-control"
                        id="email"
                        required="required"
                        placeholder={t("form-email-placeholder")}
                        data-error={t("required-field-message")}
                        value={inputs.email}
                        onChange={handleInputChange}
                      />
                      <label htmlFor="email">{t("email")}</label>
                      <div className="help-block with-errors mt-20"></div>
                    </div>
                  </div>
                  <div
                    className="col-lg-12 col-md-12 col-sm-12"
                    data-aos={"fade-up"}
                    data-aos-delay={100}
                    data-aos-duration={700}
                  >
                    <div className="form-floating">
                      <input
                        type="text"
                        name="phone"
                        className="form-control"
                        id="phone"
                        required="required"
                        placeholder={t("phone")}
                        data-error={t("required-field-message")}
                        value={inputs.phone}
                        onChange={handleInputChange}
                      />
                      <label htmlFor="phone">{t("phone")}</label>
                      <div className="help-block with-errors mt-20"></div>
                    </div>
                  </div>
                </div>
             
                <div className="text-center">
                  <button
                    type="submit"
                    name="submit"
                    className="btn btn-color btn-circle"
                    disabled={isLoading}
                  >
                    {isLoading ? ". . ." : t("register_retreat")}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Loader>
  );
};

export default RetreatForm;
