import React, { useState } from "react";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

const RetreatPreview = ({ retreat, index, isNextPreview, ...props }) => {

  const { t } = useTranslation();
  const history = useHistory();
  const [isFullscreen, setIsFullscreen] = useState(false);

  const parsedDate = new Date(retreat.date);
  const dateParts = {
    day: format(parsedDate, "dd"),
    monthNumber: format(parsedDate, "MM"),
    monthName: format(parsedDate, "MMMM"),
    year: format(parsedDate, "yyyy"),
    time: format(parsedDate, "hh:mm aa"),
    date: format(parsedDate, "yyyy-MM-dd"),
  };

  return (
    <div
      className={`event-preview-big white-bg text-center`}
      style={{
        padding: "3px",
        margin: "2px",
        cursor: "pointer",
      }}
      title={retreat.name}
      onClick={() => {
        history.push(`${process.env.PUBLIC_URL}/retreats/${retreat.hashid}`);
      }}
    >
      <div className="post-img" style={{position: 'relative'}}>
        <div className= 'retreat-preview-title'>{retreat.name}</div>
        <div className= 'retreat-preview-date'>{retreat.date_range}</div>
        <img
          className="img-fluid event-image"
          src={retreat.image.url}
          alt=""
          style={{
            objectFit: "cover",
          }}
        />
      </div>
    </div>
  );
};
export default RetreatPreview;
