import React from "react";
import ContentLoader from "react-content-loader";

import teamImage from "../../assets/images/team/team-02.jpg";

const TeamMember = ({ name, role, image, isLoading, ...otherProps }) => {
  return (
    <div className="member text-center mt-o">
      <div
        className="team-member-container"
        style={{ position: "relative !important" }}
      >
        {isLoading ? (
          <ContentLoader
            speed={1}
            height={270}
            backgroundColor="#ECECEC"
            foregroundColor="#F5F3F1"
            style={{
              width: "100%",
              height: "100%",
            }}
          >
            <rect x="0" y="0" rx="5" ry="5" width="100%" height="230" />
            <rect x="15" y="240" rx="5" ry="5" width="100%" height="10" />
            <rect x="30" y="260" rx="5" ry="5" width="100%" height="7" />
          </ContentLoader>
        ) : (
          <img
            src={image ? require(`../../assets/images/${image}`) : teamImage}
            className="img-fluid"
            style={{
              height: "350px",
            }}
            alt="team-01"
          />
        )}
      </div>
      {!isLoading && (
        <div className="white-bg pt-20 pb-20 box-shadow-hover">
          <h4 className="member-title">{name}</h4>
          <p className="member-subtitle">{role}</p>
        </div>
      )}
    </div>
  );
};

export default TeamMember;
